
// @font-face {
//     font-family: 'ArefRuqaa';
//     src: url('/fonts/ArefRuqaa-Regular.ttf');
// }
// * {
//   font-family: 'ArefRuqaa';
// }
html{
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;  /* Firefox */
}
html::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
// html {
//   overflow: hidden !important; /* Hide scrollbars */
// }
[v-cloak]{ display:none }
.server-down-message {
    position: fixed !important;
    z-index: 99;
    width: 40%;
    margin: 5vh auto;
    left: 0;
    right: 0;
}
@media screen and (max-height: 630px) {
  .server-down-message {
    width: 60%;
  }
}
